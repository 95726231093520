import React from "react";
import Head from "next/head";
import styled from "styled-components";
import { FirstSection } from "components/Sections/FirstSection";
import { AboutSection } from "components/Sections/AboutSection";
import { NewsSection } from "components/Sections/NewsSection";

const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 0;
`;

const Avatar = styled.div`
  width: 80%;
  height: 100%;

  position: absolute;
  bottom: 0;
  right: -10rem;

  background: url("/images/avatar.png") no-repeat;
  background-size: contain;
  background-position: center bottom;
`;

export default function Home() {
  return (
    <Content>
      <Head>
        <title>Faroeste</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      
      <FirstSection />
      <AboutSection />
      <NewsSection />

    </Content>
  );
}
