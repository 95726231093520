import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Link from "next/link";

const StyledContainer = styled(Container)`
  position: relative;
  padding: 0 5rem;

  padding-bottom: 5rem;

    @media (max-width: 800px) {
        padding: 2rem;
    }
`;

const Title = styled(Typography).attrs({ as: "h1" })`
    font-size: 4rem;
    line-height: 0;
    font-family: "Catalog-Bold";
    text-transform: uppercase;
    position: absolute;
    top: -2.5rem;
`

const Content = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;

    background: rgba(20, 20, 20, 0.9);
    padding-block: 60px;

    padding-inline: 5rem;

    position: relative;

    @media (max-width: 800px) {
        padding: 3rem 1rem;
        max-width: 99%;
        flex-direction: column;
    }
`;

const ItemsList = styled(Container)`
    display: flex;
    flex-direction: rows;
    gap: 3rem;

    @media (max-width: 800px) {
        padding: 1px;
        max-width: 99%;
        flex-direction: column;
    }
`;


const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left:0;
  top:0;
  background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.90) 100%);
  backdrop-filter: blur(2px);
`
const ItemContainer = styled(Container)`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: end;

    min-height: 30rem;
    padding: 2rem;

    cursor: pointer;

    &:hover{
        & > * {
            opacity: 0.9 !important;
            backdrop-filter: blur(0px);
        }
    }
`
const ItemTitle = styled(Typography).attrs({ as: "h1" })`
    font-size: 20px;
    font-family: "Redemption";
    z-index: 1;

    opacity: 0.5;
`
const DescriptionItem = styled(Typography).attrs({ as: "span" })`
    font-size: 13px;
    z-index: 1;
    opacity: 0.5;
`

const NewsSection = () => {

    return (
        <StyledContainer>

            <Content>
                <Title> Notícias </Title>
                <ItemsList>

                    <NewsItem
                        title={"O Retorno do Faroeste Roleplay"}
                        description={
                        "Estamos de volta! Depois de um período de inatividade, o Faroeste Roleplay retorna para oferecer a você a melhor experiência de roleplay em RedM..."
                        }
                        bg={"https://media-rockstargames-com.akamaized.net/tina-uploads/tina-modules/reddeadredemption/3e043fcced1e13fbdfb106c1d3ada71707e7414c.jpg"}
                        link={"https://forum.nxtstudio.net/homepage/articles/o-retorno-do-faroeste-roleplay-r3/"}
                    />
                    <NewsItem
                        title={"Novidades no Relançamento"}
                        description={
                        "O relançamento do Faroeste Roleplay traz uma série de novidades que vão transformar sua experiência no servidor..."
                        }
                        bg={"https://t2.tudocdn.net/381150?w=1200&h=1200"}
                        link={"https://forum.nxtstudio.net/homepage/articles/novidades-no-relan%C3%A7amento-r4/"}
                    />
                    <NewsItem
                        title={"Nossa História e Futuro"}
                        description={
                        "Desde sua criação, o Faroeste Roleplay se destacou como o primeiro e melhor servidor de RedM no Brasil..."
                        }
                        bg={"https://i0.wp.com/www.gamersegames.com.br/wp-content/uploads/2023/08/Novidades-do-Red-Dead-Online-02-08-2023-2.jpg?ssl=1"}
                        link={"https://forum.nxtstudio.net/homepage/articles/nossa-hist%C3%B3ria-e-futuro-r5/"}
                    />
                </ItemsList>
            </Content>
        
        </StyledContainer>
    )
}

const NewsItem = ({ title, description, bg , link} : { title: string; description: string; bg: string; link: string}) => {
    return (
        <Link
            href={link}
            style={{ color: 'inherit', textDecoration: 'inherit'}}
        >
            <ItemContainer
                style={{
                    background: `url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                }}
            >
                <Gradient />

                <ItemTitle>
                    { title }
                </ItemTitle>

                <DescriptionItem>
                    { description }
                </DescriptionItem>

            </ItemContainer>
        </Link>
    );
}

export { NewsSection }