import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const StyledContainer = styled(Container)`
  min-height: 100vh;
  position: relative;
`;


const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  background: url("/images/background.png") no-repeat;
  background-size: cover;
  background-position: center top;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:-1;
  top: 0;
`

const Gradient = styled.div`
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (max-width: 800px) {
    z-index: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 15rem;
  align-items: center;
`;

const Disclaimer = styled.div`
  display:flex;
  position: absolute;
  flex-direction: row;
  align-items: center;

  bottom: 3rem;
  width: 90%;

  gap: 20px;

    
  @media (max-width: 800px) {
    z-index:2;
  }
`;

const BoxLogo = styled.div`
  display: flex;
`;

const DisclaimerButton = styled(Button)`
  position: relative;
  padding: 15px;
  min-height: 5rem;
  height: 100%;
  background: white;
  color: black;
`;

const Avatar = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  bottom: 0;
  right: -35%;

  background: url("/images/avatar.png") no-repeat;
  background-size: contain;
  background-position: center bottom;
  
  @media (max-width: 800px) {
    width: 250%;
    right: -100%;
    bottom: 0%;
    z-index: -1 !important;
  }
`;

const FirstSection = () => {

    return (
      <div style={{
        position: "relative"
      }}>
            
        <Background />
        <Gradient>
            <StyledContainer>
                <Avatar />
                <Disclaimer>
                    <DisclaimerButton> <ArrowForwardIosIcon /> </DisclaimerButton>
                    Seja bem-vindo ao Faroeste Roleplay, o primeiro e melhor servidor de RedM no Brasil! 
                    Estamos empolgados em anunciar o relançamento deste icônico servidor, trazendo uma nova e imersiva 
                    experiência de roleplay para todos os entusiastas de Red Dead Redemption 2. 
                    Prepare-se para mergulhar em um mundo repleto de aventuras, personagens inesquecíveis e histórias épicas no Velho Oeste.
                </Disclaimer>
            </StyledContainer>
        </Gradient>
        
      </div>
    )
}

export { FirstSection }