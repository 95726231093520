import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Logo from "components/Layout/Logo";
import { Typography } from "@material-ui/core";

const StyledContainer = styled(Container)`
  position: relative;
  padding: 10rem 5rem;

    @media (max-width: 800px) {
        padding: 2rem 2rem;
    }
`;

const Title = styled(Typography).attrs({ as: "h1" })`
    font-size: 4rem;
    line-height: 0;
    font-family: "Catalog-Bold";
    text-transform: uppercase;
    position: absolute;
    top: -2.5rem;
`

const Content = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;

    background: rgba(20, 20, 20, 0.9);
    padding-block: 60px;

    padding-inline: 5rem;

    position: relative;

    @media (max-width: 800px) {
        flex-direction: column;
        padding: 3rem 2rem;
    }

`;

const LeftContent = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 40%;

    padding: 0;
    margin: 0;

    & > {
        line-height: 2rem;
    }

    @media (max-width: 800px) {
        max-width: 99%;
    }
`;

const RightContent = styled(Container)`
    position: absolute;
    background: url("/images/home-background.jpg") no-repeat;
    background-size: cover;
    background-position: center top;
    background-color: rgba(0, 0, 0, 0.5);
    max-width: 40%;

    right: 0;
    bottom: 0;
    height: 115%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;

    @media (max-width: 800px) {
        display: none;
        padding: 1px;
        max-width: 99%;
    }
`;

const AboutSection = () => {

    return (
        <StyledContainer>
            <Content>
                <LeftContent>
                    <Title> Sobre o Faroeste </Title>
                    <span> 
                        Com base no feedback da nossa comunidade e melhorias tecnológicas, nós reimaginamos cada aspecto do servidor para oferecer uma jornada ainda mais autêntica e emocionante. 
                        Novos scripts, missões envolventes e uma economia balanceada são apenas algumas das novidades que você encontrará.

                        
                     </span>
                     <span>
                        Mais do que um jogo, uma comunidade. 
                        Desde a sua criação, fomos pioneiros no roleplay de RedM no Brasil, estabelecendo um padrão de qualidade e diversão que conquistou milhares de jogadores. 
                        Este relançamento é uma celebração do nosso legado e uma promessa de continuar a oferecer o melhor do roleplay.

                     </span>
                </LeftContent>

                <RightContent>
                    <Logo />
                </RightContent>
            </Content>
            
        </StyledContainer>
    )
}

export { AboutSection }